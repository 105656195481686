import CreatSlug from 'utils/createSlug';
import changeNameImages from 'utils/changeNameImages';
import pointsChecocards from 'lib/pointsChecocards';
import cacsChecocards from 'lib/cacsChecocards';
import faqs from 'lib/faqs';

const NewService = {
  news: [
    {
      image: changeNameImages('/assets/images/news/checocards-novedades-puntos.jpg'), 
      title: 'Conoce las ubicaciones de las Checo Cards', 
      content: [
        {html: '<h2>Puntos de interes</h2>'},
        {html: pointsChecocards},
        {html: "<h2>CAC's Telcel</h2>"},
        {html: cacsChecocards}
      ]
    },
    {
      image: changeNameImages('/assets/images/news/checo-texteando.png'),
      title: 'Así puedes compartir y recibir Checo Cards',
      content: [
        { text: '¿Te faltan tarjetas para completar tu colección? Ya puedes transferir tus Checo Cards repetidas con tus amigos, tu familia, tus vecinos, con tu jefe ¡con quien tu quieras! Recuerda que todos pueden participar para ganar increíbles premios COMPLETANDO SU ÁLBUM. ¡Diviértete y encuéntralas todas!' },
        { html: '<h3>Sigue los siguientes pasos:</h3>' },
        { html: '<b>Paso 1:</b><br>Entra a <a href="https://checocardstelcel.com/">https://checocardstelcel.com/</a> con tu usuario y contraseña.' },
        { html: '<b>Paso 2:</b><br>Busca la tarjeta repetida que quieras compartir.  Como mínimo, necesitas 2 tarjetas iguales para hacer la transferencia. Recuerda que solo puedes obtener máximo 5 veces la misma tarjeta.' },
        { html: '<b>Paso 3:</b><br>Da clic al botón <b>TRANSFERIR TARJETA</b> el cual te llevará a tus contactos de Whatsapp*.' },
        { html: '<b>Paso 4:</b><br>Selecciona a la persona que quieras compartir  y manda la liga.' },
        { html: '<b>Paso 5:</b><br>Una vez que recibes la liga, da clic en ella.<br> Recuerda: Tienes que tener tu sesión abierta. <a href="/login"><b>Inicia sesión</b></a> o <a href="/registro"><b>regístrate aquí</b></a>.' },
        { html: '<b>Paso 6:</b><br>Da clic al botón <b>GUARDAR TARJETA.</b>' },
        { text: '¡Y listo! Ya hiciste una transferencia. Así que sigue compartiendo y completa tu álbum con las Checo Cards ¡antes que nadie!' },
        { html: '<i>* Para hacer la transferencia por WhatsApp, deberás contar con conexión a internet. Las transferencias serán SOLO POR WHATSAPP, no aplica a otro medio o red social. Podrás compartir sin límite de transferencias tus Checo Cards.</i>' },
      ]
    },
    {
      image: changeNameImages('/assets/images/news/checocards-novedades-faqs.jpg'), 
      title: 'Preguntas Frecuentes (FAQs)', 
      content: [
        {html: faqs}
      ]
    },
    
  ],

  getNews() {
    return this.news;
  },

  getNew(slug) {
    return this.news.find(_new => {
      if (CreatSlug(_new.title) === slug) {
        return _new;
      }
    });
  }
}

export default NewService;